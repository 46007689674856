import {Component, EventEmitter, Output, Input, ViewChild, OnInit, ElementRef, TemplateRef, Inject} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import {Router} from '@angular/router';
import {DataService, Response} from '../../dataservice/data.service';
import {GlobalVariable} from '../../app.component';
import {ToastrService} from 'ngx-toastr';
import {CommonService} from '../../services/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  userDetails: any;
  public show: boolean = false;
  avatorName: any;
  walletBalance: any;

  constructor(private modalService: NgbModal, @Inject(LOCAL_STORAGE) private storage: WebStorageService,
              private router: Router, private HttpService: DataService,
              private toastrService: ToastrService, private commonservice: CommonService) {
  }

  ngOnInit() {
    this.show = true;
    this.userDetails = this.storage.get('user_details');
    this.avatorName = this.userDetails.name.substring(0, 2);
    this.walletBalance = 0;
    this.commonservice.userDetail.subscribe(res => {
      if (res !== null) {
        this.userDetails = res;
        this.avatorName = this.userDetails.name.substring(0, 2);
      }
    });
    this.setWalletBalance();
    this.commonservice.WalletBalance.subscribe(res => {
      if (res >= 0) {
        this.walletBalance = res;
      }
    });
    this.commonservice.sidebar_menu.subscribe(res => {
      this.show = res;
    });

  }

  setWalletBalance() {
    this.HttpService.get(this.BASE_URL.DASHBOARD_DATA).subscribe(res => {
      if (res.code === 200) {
        this.commonservice.setWalletBalance(res.data['wallet']);
      }
    });
  }

  /** Simple method to toggle element visibility */
  public toggle(): void {
    this.show = !this.show;
    this.commonservice.sideMenuBar(this.show);
  }

  /**
   * Signout and redirect to sign in page
   * @memberof HeaderComponent
   */
  logout() {
    this.HttpService.delete(this.BASE_URL.LOGOUT_URL).subscribe(res => {

    });
    this.storage.remove('access_token');
    this.storage.remove('user_details');
    this.router.navigate(['/login']);
  }

  handleOpenChangePassword(modalId) {
    this.modalService.open(modalId, {
      size: 'lg',
      centered: true,
    });
  }

  close() {
    this.modalService.dismissAll();
  }

}
