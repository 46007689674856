import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { AuthService } from '../app/auth/auth.service';
import { Router, RouterStateSnapshot, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Location, LocationStrategy, PlatformLocation } from '@angular/common';
import { CommonService } from '../app/services/common.service';
import { PusherService } from '../app/services/pusher/pusher.service';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { Spinkit } from 'ng-http-loader';
import { filter } from 'rxjs/operators';

export interface Options {
  heading?: string;
  removeFooter?: boolean;
}

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})

export class AppComponent implements OnInit {
  public spinkit = Spinkit;
  _router: any;
  routeOptions: Options;
  constructor(private location: Location, private locationredirect: PlatformLocation, private commonservice: CommonService,
    translate: TranslateService, public auth: AuthService, private router: Router, private route: ActivatedRoute,
    private pusherService: PusherService, @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private titleService: Title) {
    locationredirect.onPopState(() => {
      const route_titles = this.location.path().split('/');
      const route_params = route_titles[1].charAt(0).toLocaleUpperCase() + route_titles[1].slice(1);
      if (this.auth.isAuthenticated() && this.location.path() === '/login') {
        history.go(1);
      } else if (this.auth.isAuthenticated() && this.location.path() === '/dashboard') {
        this.router.navigate(['../dashboard']);
      } else {
        this.commonservice.menuDisplaySelection(route_params);
      }
    });
  }

  ngOnInit() {
    if (this.auth.isAuthenticated() && this.location.path() === '/login') {
      this.router.navigate(['../dashboard']);
      return true;
    }
    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Scroll to top on view load
        this.runOnRouteChange();
      });
  }
  runOnRouteChange(): void {
    this.route.children.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      this.routeOptions = activeRoute.snapshot.data;
    });
    const tit = this.location.path().split('/');
    //console.log(tit+'rrrrr');
    this.setTitle(tit[1].charAt(0).toUpperCase() + tit[1].slice(1));
  }

  setTitle(newTitle: string) {

    this.titleService.setTitle(
      'Qwetu | ' + newTitle
    );
  }

}




/**
 * Global variable declaration for api route
 */
export const GlobalVariable = Object.freeze({
  BASE_API_URL: environment.apiHost,

  /* (1) User Registration */
  REGISTER_URL: '/register', //post
  LOGIN_URL: '/login', //post
  LOGOUT_URL: '/logout', //post
  DASHBOARD_DATA: '/dashboard', //get
  FORGOTPASS_URL: '/forgotpass-otp', //post
  VERIFYOTP_URL: '/verify-otp', //post
  UPDATE_PASSWORD_URL: '/update-password', //post
  CHANGE_PASSWORD_URL: '/change-password', //post

  /* (2) User Profile */
  USER_PROFILE_ID: '/profiles/', //put
  USER_PROFILE_DETAILS: '/user-profile/', //get
  // USER_PROFILE_IMAGE_UPLOAD: '/user-profile/update-profile-pic', //profile
  USER_PROFILE_IMAGE_UPLOAD: "/profiles/:profileId/update-profile-pic",
  USER_AGREEMENT_DOWNLOAD_URL: '/user-agreement/download', //POST
  USER_AGREEMENT_AVAIL_URL: '/agreement-status', //get
  GUARDIAN_DETAIL_URL: '/guardian-details', //get
  GUARDIAN_DETAIL_UPDATE_URL: '/profiles/:userId/update-guarantor',
  USER_RENTAL_PLAN_URL: '/user-rental-plan',
  USER_MOBILE_CHANGE_OTP_URL: '/account-settings/get-otp',
  USER_MOBILE_VEIRIFY_OTP_URL: '/account-settings/verify-otp',
  RESIDENT_ACCOUNT_URL: '/profiles/:userId/update-resident-account',

  /* (3) MetaData */
  COUNTRY_LIST_URL: '/countries-list', //get
  SALUTATIONS_LIST: '/salutation', //get
  DOCUMENT_NAME_LIST: '/document-name-list', //get
  DOCUMENT_TYPE_LIST: '/document-type-list', //get
  YEAR_OF_STUDY_LIST: '/year-of-study-list', //get
  INSTITUTIONS_LIST: '/institutions', //get
  CURRENCIES_LIST: '/currencies-list  ', //get
  DOC_BY_ID_LIST: '/get-document-type/', //get

  /* (4) Onboarding Document */
  ON_BOARDING_DOCUMENT: '/onboarding-document', //get & post
  ON_BOARDING_DOCUMENT_UPDATE_URL: '/onboarding-document/update/', //get & post
  ON_BOARDING_DOCUMENT_SLUG: '/onboarding-document/', //get
  ON_BOARDING_DOCUMENT_UPDATE_SLUG: '/onboarding-document/update/', //post

  /* (5) Resident Checkin Status */
  CHECK_IN_STATUS: '/checkin-status', //get

  /* (6) Resident Room */
  DASHBOARD: '/dashboard', //get and use post also
  RESIDENT_ROOM: '/resident-room', //get
  RESIDENT_DASHBOARD_URL: '/resident-dashboard-content',
  RESIDENT_USER_BOOKING_URL: '/user-bookings',
  MAKE_PAYMENT: '/payments/stk-request',

  /* (7) Room Booking */
  ONBOARDING: '/onboarding', //post
  CHECK_AVAILABILITY: '/check-availability', //get
  BOOKING_HISTORY: '/user-bookings',//get

  /* (8) Forgotpassword otp */

  /** Download pdf file url */
  DOWNLOAD_AGREEMENT_URL: 'user-agreement/download/file',
  DELETE_AGREEMENT_URL: '/user-agreement/delete',
  DOWNLOAD_RESIDENT_AGREEMENT_URL: "/admin/user-bookings/download-agreement",


  DOWNLOAD_CHECK_URL: '/downloadCheck',

  TICKET_CATEGORIES_META_URL: '/ticket-categories',//get
  TICKET_STATUS_META_URL: '/ticket-status-meta',//get
  TICKET_LIST_URL: '/tickets',//get
  TICKET_ADD_URL: '/tickets',//post
  TICKET_DETAILS_URL: '/tickets/',//get
  TICKET_STATUS_UPDATE_URL: '/update-ticket-status',//post
  TICKET_LOCATION_META_URL: '/ticket-locations',//post
  /**PAYMENTS */
  PAYMENT_LIST_URL: '/user-payments-bookings',//get
  PAYMENT_UPDATE_URL: '/user-payments-bookings',//post
  PAYMENT_DETAILS_URL: '/user-payments-bookings/',//get
  PAYMENT_LOG_URL: '/payment-log',//get
  /** Upload ticket attachments */
  UPLOAD_TICKET_ATTACHMENT: '/update-ticket-attachments',
  WAITING_LIST_URL: '/waiting-list',//get
  DOWNLOAD_PAYMENT_RECEIPT_URL: '/download-payment-receipt/',//get
  DOWNLOAD_RESIDENT_STATEMENTS_URL: '/accounts/download-statement',
  DOWNLOAD_INVOICE_URL: '/accounts/download-invoice',

  /*Refunds URL*/
  REFUNDS_URL: '/refunds',
  REFUND_OTP_URL: '/send-refund-otp',

  /*Notices URL */
  NOTICES_URL: '/notices',

  /* Electricity tokens URL */
  ELECTRICITY_TOKENS_URL: '/electricity-tokens',

  /*Visitors logs URL */
  VISITOR_INVITES_URL: '/vizmo-invites',
  VISITOR_LOGS_URL: '/vizmo-visitors',
  FEEDBACKS_URL: '/feedbacks',

});

