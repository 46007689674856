import { Component, OnInit, ViewChild, TemplateRef, ElementRef, HostListener, Inject, } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DataService, Response } from '../../dataservice/data.service';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { FormGroup, FormControl, Validators, FormBuilder , NgForm } from '@angular/forms';
import { GlobalVariable } from '../../app.component';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { Options } from 'ng5-slider';
import { Observable, forkJoin } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-waitinglist',
  templateUrl: './waitinglist.component.html',
  styleUrls: ['./waitinglist.component.css']
})
export class WaitinglistComponent implements OnInit {
  private BASE_API_URL = GlobalVariable;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    columnDefs: [ {
      'targets': [7], /* column index [0,1,2,3]*/
      'orderable': false, /* true or false */
      }],
  };
  dtTrigger: Subject<any> = new Subject();
  dateRange: any; 
  waitingList: any=[];
  waitData: any=[];
  walletAmount: number;
  amountPaid: number;
  registerAmount: number;
  amountToPaid: number;
  waitingId: any;
  loading: boolean = false;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private HttpService: DataService,
              public formBuilder: FormBuilder , private commonservice: CommonService, private modalService: NgbModal,
              private toastrService: ToastrService,  private elementRef: ElementRef, private router: Router) { }
  ngOnInit() {
    this.getWaitingList();
  }

  getWaitingList() {
    this.HttpService.get(this.BASE_API_URL.WAITING_LIST_URL).subscribe(res=>{
      if(res.code===200){
        this.waitingList =res.data;
        this.dtTrigger.next();
      } else {
        this.waitingList = [];
        this.rerender();
      }
    });
  }

  ngAfterViewInit (): void {
    // setTimeout(() => {
    //   this.dtTrigger.next();
    // }, 1500);
  }

  ngOnDestroy() {
  //  this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
   }
  /**
   * Common modal open popup function
   * @param data
   * @param key
   * @param myModel
   */
  open(myModel, waitid) {
    this.waitingId=waitid;
    this.modalService.dismissAll(myModel);
    this.HttpService.get(this.BASE_API_URL.WAITING_LIST_URL+'/'+waitid+'/edit').subscribe(res => {
      if (res.code==200) {
        this.waitData = res.data;
        this.walletAmount = this.waitData.wallet_amount;
        this.amountPaid = this.waitData.amount_paid;
        this.registerAmount = this.waitData.total_amount;
        this.amountToPaid = this.waitData.to_be_paid;
        this.modalService.open(myModel, { size: 'lg',
        windowClass: 'modalfeedback', centered: true
       });
      } else {
        
      }
    }, error => {
      this.loading = false;
      this.walletAmount = 0;
      this.amountPaid = 0;
      this.registerAmount = 0;
      this.amountToPaid = 0;
      this.toastrService.error(error.error.message);
    });
  }

  close() {
    this.modalService.dismissAll();
  }

  paynow (){
    this.loading = true;
    const data = {
      'id': this.waitingId
    };
    this.HttpService.postjson(this.BASE_API_URL.WAITING_LIST_URL, data).subscribe(res=>{
      if (res.code==200){
        this.toastrService.success(res.status);
        this.loading = false;
        this.close();
        this.getWaitingList();
      }
    }, error => {
      this.loading=false;
      this.toastrService.error(error.error.message);
    });

  }

}
