import { Component, OnInit, ViewChild, TemplateRef, ElementRef, HostListener, Inject, } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DataService, Response } from '../../dataservice/data.service';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { FormGroup, FormControl, Validators, FormBuilder , NgForm } from '@angular/forms';
import { GlobalVariable } from '../../app.component';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { Options } from 'ng5-slider';
import { Observable, forkJoin } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-agreement',
  templateUrl: './agreement.component.html',
  styleUrls: ['./agreement.component.css']
})
export class AgreementComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  canDownload: boolean = false;
  loading : Boolean =  false;
  downLoadData: any = [];
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private HttpService: DataService,
              public formBuilder: FormBuilder, private commonservice: CommonService, private modalService: NgbModal,
              private toastrService: ToastrService, private elementRef: ElementRef, private router: Router,
              public ngxSmartModalService: NgxSmartModalService, private http: HttpClient) { }

  ngOnInit() {
    this.loading = true;
    this.HttpService.get(this.BASE_URL.USER_AGREEMENT_AVAIL_URL).subscribe(res => {
      this.canDownload=res.data['status'];
      this.loading = false;
    });
  }

  downloadAgreement() {
    try {
      const API_URL = this.BASE_URL.BASE_API_URL + this.BASE_URL.USER_AGREEMENT_DOWNLOAD_URL;
      const httpOptions = {
        'responseType'  : 'arraybuffer' as 'json'
      };
      this.http.get(API_URL, httpOptions).subscribe((response: any) => {
        let file = new Blob([response], { type: 'application/pdf' });
        let fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }, (err) => {
        this.toastrService.error(err.message);
      });
    }
    catch (e) {
      this.toastrService.error(e.toString());
    }
  }

}
