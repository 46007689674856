import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { DataService, Response } from '../dataservice/data.service';
import { GlobalVariable } from '../app.component';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public page_no = new BehaviorSubject (1);
  public menu_selection = new BehaviorSubject(true);
  public profile_selection = new BehaviorSubject(null);
  public menu_display_selection = new BehaviorSubject(null);
  public sidebar_menu = new BehaviorSubject(null);
  public userDetail = new BehaviorSubject(null);
  public forgotPassmail = new BehaviorSubject(null);
  public review_setting = new BehaviorSubject(null);
  public ticketID = new BehaviorSubject(null);
  public WalletBalance = new BehaviorSubject(null);


  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService) { }

  /**
   * Reset page number
   *
   * @param {*} page_no
   * @memberof CommonService
   */
  resetPageNo(page_no) {
    this.page_no.next(page_no);
  }

  /**
   * Side menu selection based on send review invite click
   *
   * @param {*} status
   * @memberof
   */
  sideMenuSelection(status) {
    this.menu_selection.next(status);
  }

  profile(client){
    this.profile_selection.next(client);
  }

  /**
   * Menu display selection
   *
   * @param {*} status
   * @memberof
   */
  menuDisplaySelection(status) {
    this.menu_display_selection.next(status);
  }

  sideMenuBar(status) {
    this.sidebar_menu.next(status);
  }

  /**
   * Set userDetails
   * @param {*} data
   * @memberof
   */
  userData(data) {
    this.userDetail.next(data);
  }
  /**
   * Set userDetails
   * @param {*} data
   * @memberof
   */
  setTicketID(data) {
    this.ticketID.next(data);
  }

  /**
   * forgot password mailid store
   */

   forgotMail(data) {
    this.forgotPassmail.next(data);
   }


   showReviewSetting(review) {
    this.review_setting.next(review);
  }

  setWalletBalance (data) {
    this.WalletBalance.next(data);
  }

}
