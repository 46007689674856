import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './core';
import { AuthLayoutComponent } from './core';
import { AuthGuardService as AuthGuard } from './auth/auth-guard.service';

export const AppRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },


  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './account/account.module#AccountModule'
      },
      {
        path: 'account',
        loadChildren: './account/account.module#AccountModule'
      }
    ]
  },

  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard] ,
    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'profile',
        loadChildren: './main-profile/main-profile.module#MainProfileModule'
      },
      {
        path: 'payment',
        loadChildren: './payment/payment.module#PaymentModule'
      },
      {
        path: 'bookinghistory',
        loadChildren: './bookinghistory/bookinghistory.module#BookinghistoryModule'
      },
      {
        path: 'ticket',
        loadChildren: './ticket/ticket.module#TicketModule'
      },
      {
        path: 'ticketdetail',
        loadChildren: './ticketdetail/ticketdetail.module#TicketdetailModule'
      },
      {
        path: 'notice-board',
        loadChildren: './notice-board/notice-board.module#NoticeBoardModule'
      },
      {
        path: 'electricity',
        loadChildren: './electricity/electricity.module#ElectricityModule'
      },
      {
        path: 'visitors-log',
        loadChildren: './visitors-log/visitors-log.module#VisitorsLogModule'
      },
      { path: '**', redirectTo: '/login' }
    ]
  }
];

