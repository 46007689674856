import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { RouterModule } from '@angular/router';
import {NgbModalModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AdminLayoutComponent } from './core/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './core/auth-layout/auth-layout.component';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { MenuComponent } from './core/menu/menu.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { StorageServiceModule } from 'angular-webstorage-service';
import { JwtModule } from '@auth0/angular-jwt';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { AvatarModule } from 'ngx-avatar';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MyHttpInterceptor } from './interceptor/my-http-interceptor';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { DataService } from './dataservice/data.service';
import { TabsModule } from 'ngx-tabset';
import { SharedModule } from './common/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { DataTablesModule } from 'angular-datatables';
import { MomentModule } from 'ngx-moment';
import { NgxFileDropModule } from 'ngx-file-drop';
import {ConfirmModalComponent} from './common/confirm-modal/confirm-modal.component';
//import { MainProfileComponent } from './main-profile/main-profile.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    FooterComponent,
    HeaderComponent,
    MenuComponent,
    SidebarComponent,
    ConfirmModalComponent
  //  BookinghistoryComponent,
  //  MainProfileComponent,

  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        HttpModule,
        NgbModule,
        NgbModalModule,
        NgxFileDropModule,
        // OwlDateTimeModule,
        // OwlNativeDateTimeModule,
        // NgxSmartModalModule.forRoot(),
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                'm': 59
            }
        }),
        RouterModule.forRoot(AppRoutes, {useHash: false}),
        NgHttpLoaderModule.forRoot(),
        StorageServiceModule,
        IconSpriteModule,
        AvatarModule,
        DataTablesModule,
        TabsModule.forRoot(),
        ToastrModule.forRoot({
            preventDuplicates: true,
            closeButton: true,
        }),

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }),
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                whitelistedDomains: ['http://localhost:4200'],
                blacklistedRoutes: ['http://localhost:4200/#/login']
            }
        }),
        NgxPaginationModule,
        SharedModule
    ],
  exports: [
    NgxFileDropModule,
  ],
  entryComponents:[ConfirmModalComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  providers: [DataService,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: MyHttpInterceptor,
    multi: true
  },
],
})
export class AppModule {}
