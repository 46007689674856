import {Component, ElementRef, NgZone, OnInit, OnDestroy, ViewChild, Inject, Renderer} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {CommonService} from '../../services/common.service';
import {DataService, Response} from '../../dataservice/data.service';
import {GlobalVariable} from '../../app.component';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import {Location} from '@angular/common';
import {trigger, state, style, animate, transition} from '@angular/animations';

const SMALL_WIDTH_BREAKPOINT = 991;

export interface Options {
  heading?: string;
  removeFooter?: boolean;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],
  animations: [
    trigger('collapse', [
      state('open', style({
        opacity: '1',
        display: 'block',
        transform: 'translate3d(0, 0, 0)'
      })),
      state('closed', style({
        opacity: '0',
        display: 'none',
        transform: 'translate3d(0, -100%, 0)'
      })),
      transition('closed => open', animate('200ms ease-in')),
      transition('open => closed', animate('100ms ease-out'))
    ])
  ]
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  private _router: Subscription;
  private mediaMatcher: MediaQueryList = matchMedia(
    `(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`
  );
  current_url: String;
  routeOptions: Options;
  page_no: number;
  private BASE_URL = GlobalVariable;
  outlet_id: String;
  options = {
    lang: 'en',
    theme: 'winter',
    settings: false,
    docked: false,
    boxed: false,
    opened: false
  };
  currentLang = 'en';
  @ViewChild('sidebar')
  sidebar;
  @ViewChild('sidemenu')
  sidemenu;
  // Class variables
  public show: boolean = false;

  toggle() {
    this.show = !this.show;
  }


  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private _element: ElementRef, private renderer: Renderer,
              private router: Router, private route: ActivatedRoute, public translate: TranslateService, private titleService: Title,
              zone: NgZone, private commonService: CommonService, private HttpService: DataService, private location: Location,
              private commonservice: CommonService
  ) {
    this.commonservice.sidebar_menu.asObservable().subscribe(res => {
      if (res === true) {
        this.show = true;
      } else {
        this.show = false;
      }
    });

    // this.renderer.listen('window', 'click',(e:Event)=>{

    //   console.log(this.show);
    //   //  if (this.show === true) {
    //   //   this.show = false;
    //   //  }
    // });

    this.mediaMatcher.addListener((mql: any) =>
      zone.run(() => {
        this.mediaMatcher = mql;
      })
    );
  }

  ngOnInit(): void {
    this._router = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Scroll to top on view load
        //  document.querySelector('.main-content').scrollTop = 0;
        this.runOnRouteChange();
      });

  }

  ngOnDestroy() {
    //this._router.unsubscribe();
  }

  hideShowBar() {
    if (this.show == true) {
      // this.show = false;
      this.commonService.sideMenuBar(false);
    } else {
      // this.commonService.sideMenuBar(true);
    }
  }

  runOnRouteChange(): void {
    this.route.children.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      this.routeOptions = activeRoute.snapshot.data;
    });
    const tit = this.location.path().split('/');
    this.setTitle(tit[1].charAt(0).toUpperCase() + tit[1].slice(1));
  }

  setTitle(newTitle: string) {
    this.titleService.setTitle(
      'Qwetu | ' + newTitle
    );
  }

  handleToggle() {
    this.show = !this.show;
    this.commonservice.sideMenuBar(this.show);
  }

}
