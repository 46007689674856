import { Component, OnInit, ViewChild, TemplateRef, ElementRef, HostListener, Inject, } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DataService, Response } from '../../dataservice/data.service';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm, AbstractControl } from '@angular/forms';
import { GlobalVariable } from '../../app.component';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { Options } from 'ng5-slider';
import { Observable, forkJoin } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';
import heic2any from "heic2any";
import * as moment from 'moment';

enum ResidentAccountTypes {
  MPESA = 'Mpesa',
  KENYAN_ACCOUNT = 'KenyanBankAccount',
  INTERNATIONAL_ACCOUNT = 'InternationalBankAccount'
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  userprofileForm: FormGroup;
  doucmentUploadForm: FormGroup;
  GuardiandataForm: FormGroup;
  ChangepasswordForm: FormGroup;
  ChangemobileForm: FormGroup;
  residentAccountForm: FormGroup;
  residentAccountTypes = ResidentAccountTypes;
  residentAccountFormLoader: boolean = false;
  studentRoomStatus: string
  GuardData: any;
  formdata: any;
  loading: Boolean = false;
  emailnotvalid: boolean = true;
  submitted: boolean = true;
  submittedGr: boolean = true;
  id: String;
  countryList: any = [];
  currencyList: any = [];
  salutation_list: any = [];
  documentNameList: any = [];
  documentTypeList: any = [];
  yearofstudy_list: any = [];
  instituationList: any = [];
  userDetails: any = [];
  userId: number;
  value: number = 3;
  legend: String;
  passlen: boolean = false;
  notSame: boolean = true;
  url: any;
  imageurl: any;
  updateDocId: any;
  filesToUpload: Array<File> = [];
  fileToUpload: File = null;
  uploadFor: any = [];
  documentFor: any;
  documentType: any;
  studentsDocument: any = [];
  guardianDocument: any = [];
  otpbuttonname: any;
  options: Options = {
    showTicksValues: true,
    showTicks: true,
    ceil: 4,
    floor: 3,
    stepsArray: [
      { value: 1, legend: 'Upload' },
      { value: 2, legend: 'Reject' },
      { value: 3, legend: 'Approved' },
      { value: 4, legend: 'Expired' }
    ],
    getLegend: (value: number): string => {
      return 't' + this.legend;
    },
    getTickColor: (value: number): string => {
      if (value <= 1) {
        return 'green';
      }
      if (value <= 2) {
        return 'orange';
      }
      if (value <= 3) {
        return 'orange';
      }
      if (value <= 4) {
        return 'orange';
      }
      return '#2AE02A';
    }
  };
  public max = new Date(2009, 3, 21, 20, 30);
  @ViewChild('myModal') myModal: ElementRef;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private HttpService: DataService, public formBuilder: FormBuilder,
    private commonservice: CommonService, private modalService: NgbModal, private toastrService: ToastrService,
    private elementRef: ElementRef, private router: Router, public ngxSmartModalService: NgxSmartModalService) {
  }

  ngOnInit() {
    this.loading = true;
    this.imageurl = null;
    this.updateDocId = null;
    this.otpbuttonname = 'Get OTP';
    this.userprofileForm = this.formBuilder.group({
      'title': ['1', Validators.compose([Validators.required])],
      'first_name': ['', Validators.compose([Validators.required])],
      'birth_date': ['', Validators.compose([Validators.required, this.passwordValidator.bind(this)])],
      'nationality': ['', Validators.compose([Validators.required])],
      'college': ['', Validators.compose([Validators.required])],
      'year_of_study': ['', Validators.compose([Validators.required])],
    });
    this.uploadFor = [{ 'name': 'Student' }, { 'name': 'Guardian' }];
    this.ChangepasswordForm = this.formBuilder.group({
      'current_password': ['', Validators.compose([Validators.required])],
      'new_password': ['', Validators.compose([Validators.required])],
      'new_password_confirmation': ['', Validators.compose([Validators.required])],
    });
    this.ChangemobileForm = this.formBuilder.group({
      'country_code': ['', Validators.compose([Validators.required])],
      'mobile_number': ['', Validators.compose([Validators.required])],
      'otp': ['', Validators.compose([Validators.required])]
    });

    this.HttpService.get(this.BASE_URL.RESIDENT_ROOM).subscribe(res => {
      this.studentRoomStatus = res.data["status_id"]['name'];
    });
    this.GuardiandataForm = this.formBuilder.group({
      'email': ['', Validators.compose([Validators.required])],
      'mobile_number': ['', Validators.compose([Validators.required])],
      'name': ['', Validators.compose([Validators.required])],
    });
    this.setResidentAccountForm();
    this.userDetails = this.storage.get('user_details');
    this.userId = this.userDetails.id;
    const countryData = this.HttpService.get(this.BASE_URL.COUNTRY_LIST_URL);
    const salutation = this.HttpService.get(this.BASE_URL.SALUTATIONS_LIST);
    const documentList = this.HttpService.get(this.BASE_URL.DOCUMENT_NAME_LIST);
    const documenttypeList = this.HttpService.get(this.BASE_URL.DOCUMENT_TYPE_LIST);
    const yearofstudy = this.HttpService.get(this.BASE_URL.YEAR_OF_STUDY_LIST);
    const instituations = this.HttpService.get(this.BASE_URL.INSTITUTIONS_LIST);
    const currencies = this.HttpService.get(this.BASE_URL.CURRENCIES_LIST);
    forkJoin([countryData, salutation, documentList, yearofstudy, documenttypeList, instituations, currencies]).subscribe(result => {
      this.countryList = result[0].data;
      this.salutation_list = result[1].data;
      this.documentNameList = result[2].data;
      this.yearofstudy_list = result[3].data;
      this.documentTypeList = result[4].data;
      this.instituationList = result[5].data;
      this.currencyList = result[6].data;
    }, error => {

    });
    this.HttpService.get(this.BASE_URL.USER_PROFILE_DETAILS + this.userDetails.id).subscribe(res => {
      this.userDetails = res.data;
      this.patchResidentAccountForm();
      this.imageurl = this.userDetails.profile_picture;
      var yrof;
      if (this.userDetails.year_of_study) {
        yrof = this.userDetails.year_of_study.id;
      } else {
        yrof = '';
      }
      var nati;
      if (this.userDetails.nationality) {
        nati = this.userDetails.nationality.id;
      } else {
        nati = '';
      }
      // this.userDetails =null;
      var collge;
      if (this.userDetails.college === null) {
        collge = '';
      } else {
        collge = this.userDetails.college.id;
      }
      this.userprofileForm.patchValue({
        'title': this.userDetails.title, 'first_name': this.userDetails.first_name,
        'birth_date': this.userDetails.birth_date, 'nationality': nati
        , 'college': collge, 'year_of_study': yrof, 'id_number': this.userDetails.id_number
      });
      this.ChangemobileForm.patchValue({ 'country_code': this.userDetails.dial_code });
      this.loading = false;
    });
    this.getUserDocuments()
    this.getguardianData();
    this.setValidationForForm();
  }

  passwordValidator(formControl: FormControl) {
    if (moment(moment.now()).diff(formControl.value, "years") >= 15)
      return null
    return {
      isAgeLimit: true
    }
  }

  setResidentAccountForm() {
    this.residentAccountForm = this.formBuilder.group({
      'account_holder_name': ['', Validators.compose([Validators.required])],
      'account_type': ['', Validators.compose([Validators.required])],
      'account_number': ['', Validators.compose([Validators.required])],
      'bank_name': [''],
      'bank_code': [''],
      'branch_code': [''],
      'swift_code': [''],
      'credit_currency': [''],
      'country_id': [''],
    });
  }
setValidationForForm(){
  const bank_name = this.residentAccountForm.get('bank_name');
  const branch_code = this.residentAccountForm.get('branch_code');
  const bank_code = this.residentAccountForm.get('bank_code');
  const swift_code = this.residentAccountForm.get('swift_code');
  const credit_currency = this.residentAccountForm.get('credit_currency');
  const country_id = this.residentAccountForm.get('country_id');
  this.residentAccountForm.get('account_type').valueChanges.subscribe(type=>{
    if(type==='InternationalBankAccount'){
      console.log('hits if',this.residentAccountForm)
      bank_name.setValidators([Validators.required])
      bank_code.setErrors(null);
      swift_code.setValidators([Validators.required])
      credit_currency.setValidators([Validators.required])
      country_id.setValidators([Validators.required])
      branch_code.setErrors(null)
    }else if(type==='KenyanBankAccount'){
      console.log('hits else if',this.residentAccountForm)
      bank_name.setValidators([Validators.required])
      bank_code.setValidators([Validators.required])
      swift_code.setValidators([Validators.required])
      credit_currency.clearValidators()
      country_id.clearValidators()
      branch_code.setValidators([Validators.required])
    }else{
      console.log('hits else')
      bank_name.setValidators(null)
      bank_code.setValidators(null)
      swift_code.setValidators(null)
      credit_currency.setValidators(null)
      country_id.setValidators(null)
      branch_code.setValidators(null)
    }
  })
}

  handleValidation(fieldName: string,control:AbstractControl) {
    if (!this.residentAccountForm) return null
    const type = control.parent.get('account_type').value
    console.log(this.residentAccountForm)
    //const metas=['bank_name', 'swift_code', 'credit_currency', 'country_id', 'bank_code','branch_code']
    const fieldMeta = {
      'InternationalBankAccount': ['bank_name', 'swift_code', 'credit_currency', 'country_id'],
      'KenyanBankAccount': ['bank_name', 'bank_code', 'swift_code', 'branch_code'],
      'MPESA': []
    }
    // console.log(fieldName,fieldMeta[type])
    if (fieldMeta[type] && fieldMeta[type].includes(fieldName)  && !this.residentAccountForm.get(fieldName).value){
      console.log(fieldMeta[type].includes(fieldName) , 'is here',fieldName)
      return { required: true }
    }else{
      console.log('from the else',fieldMeta[type],fieldName)
      return null
    }
  }

  patchResidentAccountForm() {
    if (this.userDetails && this.userDetails.user) {
      const residentAccount = this.userDetails.user['resident_account'];
      if (residentAccount) {
        this.residentAccountForm.patchValue({
          'account_holder_name': residentAccount['account_holder_name'],
          'account_type': residentAccount['account_type'],
          'account_number': residentAccount['account_number'],
          'bank_name': residentAccount['bank_name'],
          'bank_code': residentAccount['bank_code'],
          'branch_code': residentAccount['branch_code'],
          'swift_code': residentAccount['swift_code'],
          'credit_currency': residentAccount['credit_currency'],
          'country_id': residentAccount['country_id'],
        });
      }
    }
  }

  handleUpdateResidentForm() {
    const userId = this.userDetails.user.id;
    const residentAccountJSON = this.residentAccountForm.value;
    const API_URL = this.BASE_URL.RESIDENT_ACCOUNT_URL.replace(':userId', userId);
    this.residentAccountFormLoader = true;
    this.HttpService.put(API_URL, residentAccountJSON).subscribe(res => {
      this.residentAccountFormLoader = false;
      this.toastrService.success(res['status']);
    }, (err) => {
      this.residentAccountFormLoader = false;
      this.toastrService.error(err.error.message);
    });
  }

  getguardianData() {
    this.HttpService.get(this.BASE_URL.GUARDIAN_DETAIL_URL).subscribe(res => {
      if (res.data !== null) {
        this.GuardData = res.data;
        this.GuardiandataForm.patchValue({
          'email': this.GuardData.email,
          'mobile_number': this.GuardData.mobile_number, 'name': this.GuardData.name
        });
      }
    });
  }

  check() {
    alert(this.pass_form.currentpassword.value);
  }

  get pass_form() {
    return this.ChangepasswordForm.controls;
  }

  open(myModel) {
    this.modalService.open(myModel, {
      size: 'lg',
      windowClass: 'modalfeedback', centered: true
    });
  }


  get uploadForm() {
    return this.userprofileForm.controls;
  }

  updateGuard(form: FormGroup) {
    this.submittedGr = false;
    if (this.GuardiandataForm.invalid) {
      this.loading = false;
      return;
    }
    const data = form.value;
    const userDetails = this.storage.get('user_details');
    if (userDetails.id) {
      this.loading = true;
      this.HttpService.put(this.BASE_URL.GUARDIAN_DETAIL_UPDATE_URL.replace(":userId", userDetails.id), data).subscribe(res => {
        this.loading = false;
        this.toastrService.success(res.status);
      }, (err) => {
        this.loading = false;
        this.toastrService.error(err.error.message);
      });
    } else {
      this.toastrService.error('User id does not exist');
    }
  }

  get guardianForm() {
    return this.GuardiandataForm.controls;
  }

  /**
   * Common email vaidation
   *
   * @memberof LoginComponent
   */
  checkmail() {
    this.emailnotvalid = true;
    if (this.guardianForm.email.value.match(/^[\w\-\.\+]+\@[a-zA-Z0-9\.\-]+\.[a-zA-z0-9]{2,}$/)) {
      this.emailnotvalid = true;
    } else {
      this.emailnotvalid = false;
    }
  }

  getUserDocuments() {
    this.HttpService.get(this.BASE_URL.ON_BOARDING_DOCUMENT).subscribe(res => {
      if (res.code === 200) {
        if (res.data['Student'] !== undefined) {
          this.studentsDocument = res.data['Student'];
        }
        if (res.data['Guardian'] !== undefined) {
          this.guardianDocument = res.data['Guardian'];
        }
      }
    });
  }

  userprofile(form: FormGroup) {
    this.submitted = false;
    // stop here if form is invalid
    if (this.userprofileForm.invalid) {
      this.loading = false;
      return;
    }
    this.loading = true;
    var dat1 = new String(form.value.birth_date);
    var splitted = dat1.split('00:', 2);
    form.value.birth_date = splitted[0];
    //  form.value.birth_date= dat1.toISOString().substring(0,10);
    this.submitted = true;
    const user_details = this.storage.get('user_details');
    const data = {};
    this.formdata = new FormData();
    this.formdata = form.value;
    this.HttpService.put(this.BASE_URL.USER_PROFILE_ID + user_details.id, this.formdata).subscribe(res => {
      if (res.code === 200) {
        this.loading = false;
        this.toastrService.success(res.status);
        this.commonservice.userData(res.data);
        this.storage.set('user_details', res.data);
      } else {
        this.loading = false;
        this.toastrService.success(res.status);
      }
    }
      , error => {
        this.toastrService.error(error.error.message);
      });

  }

  close() {
    this.modalService.dismissAll();
  }

  async userprofileImage(event) {
    if (event.target.files && event.target.files[0]) {
      this.loading = true;
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      reader.onload = (e) => { // called once readAsDataURL is completed
        this.url = reader.result; // add source to image
      };
      this.fileToUpload = event.target.files[0];
      const mimeType = this.fileToUpload.type;
      if (mimeType.match(/image\/*/) == null) {
        this.toastrService.error('Selected document is of the wrong type. Upload a supported file type.');// = ;
        this.loading = false;
        return;
      }
      if (this.fileToUpload.size > 3000000) {
        this.toastrService.error('Size of the selected file must be less than 3MB.');// = ;
        this.fileToUpload = null;
        this.loading = false;
        return;
      } else {

        let file: File;
        if (/image\/hei(c|f)/.test(event.target.files[0].type)) {
          try {
            const jpgBlob = await heic2any({ blob: event.target.files[0], toType: "image/png", quality: 100 }) as Blob
            let newName = event.target.files[0].name.replace(/\.[^/.]+$/, ".jpg");
            file = new File([jpgBlob], newName);
            this.fileToUpload = file
          } catch (error) {
            console.log("Unable to convert")
          }
        }
        this.formdata = new FormData();
        this.formdata.append('document', this.fileToUpload);
        this.formdata.append('_method', "PUT");
        this.HttpService.postFormData(this.BASE_URL.USER_PROFILE_IMAGE_UPLOAD.replace(":profileId", this.userDetails.user.id), this.formdata).subscribe(res => {
          this.imageurl = res.json().data;
          this.loading = false;
        }, (err) => {
          this.loading = false;
          if (err.json() && err.json().message) {
            this.toastrService.error(err.json().message);
          }
        });
      }
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getOTP() {
    this.loading = true;
    const data = {
      'country_code': this.ChangemobileForm.controls.country_code.value,
      'mobile_number': this.ChangemobileForm.controls.mobile_number.value,
    };
    this.HttpService.postjson(this.BASE_URL.USER_MOBILE_CHANGE_OTP_URL, data).subscribe(res => {
      if (res.code == 200) {
        this.otpbuttonname = 'Resend OTP';
        this.toastrService.success(res.status);
        this.loading = false;
      }
    }, error => {
      this.loading = false;
      this.toastrService.error(error.error.message);
    });
    this.loading = false;
  }

  updatemobile() {
    const dat = {
      'otp': this.ChangemobileForm.controls.otp.value
    };
    this.HttpService.postjson(this.BASE_URL.USER_MOBILE_VEIRIFY_OTP_URL, dat).subscribe(res => {
      if (res.code == 200) {
        this.otpbuttonname = 'Get OTP';
        this.toastrService.success(res.status);
        this.commonservice.userData(res.data);
        this.storage.set('user_details', res.data);
        this.userDetails = res.data;
      }
    }, error => {
      this.toastrService.error(error.error.message);
    });
  }

}
