import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataService} from '../../dataservice/data.service';
import {ToastrService} from 'ngx-toastr';
import {GlobalVariable} from '../../app.component';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.css']
})
export class ChangePasswordFormComponent implements OnInit {

  @Output() onSuccess = new EventEmitter();
  private BASE_API_URL = GlobalVariable;
  changePasswordForm: FormGroup;
  formLoading: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private HttpService: DataService,
              private toastrService: ToastrService) { }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      'current_password' : ['', Validators.compose([Validators.required])],
      'new_password' : ['', Validators.compose([Validators.required])],
      'new_password_confirmation' : ['', Validators.compose([Validators.required])]
    },{
      validators: this.handlePasswordMatch.bind(this)
    });
  }

  handlePasswordMatch(formGroup: FormGroup) {
    const { value: password } = formGroup.get('new_password');
    const { value: confirmPassword } = formGroup.get('new_password_confirmation');
    return password === confirmPassword ? null : { passwordNotMatch: true };
  }

  handleSubmit() {
    this.formLoading = true;
    const requestJSON = this.changePasswordForm.value;
    this.HttpService.post(this.BASE_API_URL.CHANGE_PASSWORD_URL, requestJSON).subscribe(res => {
      this.formLoading = false;
      this.toastrService.success(res['status']);
      this.changePasswordForm.reset();
      this.onSuccess.emit();
    }, error => {
      this.formLoading = false;
      this.toastrService.error(error.error.message);
    });
  }

}
