import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
/* common modules */
import { NgxSmartModalModule } from 'ngx-smart-modal';
import {TabsModule} from 'ngx-tabset';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { Ng5SliderModule } from 'ng5-slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './profile/profile.component';
import { UploaddocumentComponent } from './uploaddocument/uploaddocument.component';
import { AgreementComponent } from './agreement/agreement.component';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { ExportAsModule } from 'ngx-export-as';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { DataTablesModule } from 'angular-datatables';
import { MomentModule } from 'ngx-moment';
import { RentalplanComponent } from './rentalplan/rentalplan.component';
import { PaymentlogComponent } from './paymentlog/paymentlog.component';
import { WaitinglistComponent } from './waitinglist/waitinglist.component';
import { RefundComponent } from './refund/refund.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';

@NgModule({
  declarations: [
  ProfileComponent,
  UploaddocumentComponent,
  AgreementComponent,
  RentalplanComponent,
  PaymentlogComponent,
  WaitinglistComponent,
  RefundComponent,
  ChangePasswordFormComponent],
  imports: [
    NgxSmartModalModule.forRoot(),
    Ng5SliderModule,
    TabsModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    DataTablesModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.wanderingCubes,
        backdropBackgroundColour: 'rgba(0,0,0,0.1)',
        backdropBorderRadius: '4px',
        primaryColour: '#ffffff',
        secondaryColour: '#ffffff',
        tertiaryColour: '#ffffff'
    }),
    ReactiveFormsModule,
    NgbModule,
    CommonModule,
    NgxPaginationModule,
    ExportAsModule,
    MomentModule
  ],
  exports: [
    NgxSmartModalModule,
    Ng5SliderModule,
    TabsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
    ProfileComponent,
    UploaddocumentComponent,
    AgreementComponent,
    NgbModule,
    NgxPaginationModule,
    ExportAsModule,
    MomentModule,
    DataTablesModule,
    RentalplanComponent,
    PaymentlogComponent,
    WaitinglistComponent,
    RefundComponent,
    ChangePasswordFormComponent
  ],
})
export class SharedModule { }
