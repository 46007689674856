import { Component, OnInit, ViewChild, TemplateRef, ElementRef, HostListener, Inject, } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DataService, Response } from '../../dataservice/data.service';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { FormGroup, FormControl, Validators, FormBuilder , NgForm } from '@angular/forms';
import { GlobalVariable } from '../../app.component';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { Options } from 'ng5-slider';
import { Observable, forkJoin } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-rentalplan',
  templateUrl: './rentalplan.component.html',
  styleUrls: ['./rentalplan.component.css']
})
export class RentalplanComponent implements OnInit {
  private BASE_API_URL = GlobalVariable;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    columnDefs: [ {
      'targets': [3], /* column index [0,1,2,3]*/
      'orderable': false, /* true or false */
      }],
  };
  dtTrigger: Subject<any> = new Subject();
  dateRange: any;
  rentalPlan: any;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private HttpService: DataService,
              public formBuilder: FormBuilder , private commonservice: CommonService, private modalService: NgbModal,
              private toastrService: ToastrService,  private elementRef: ElementRef, private router: Router) { }

  ngOnInit() {
    this.HttpService.get(this.BASE_API_URL.USER_RENTAL_PLAN_URL).subscribe(res => {
      if (res.code===200) {
        this.rentalPlan = res.data;
        this.dtTrigger.next();
      } else {
        this.rerender();
        this.rentalPlan = [];
      }
    });
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.dtTrigger.next();
    // }, 1500);
  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
   }

}
