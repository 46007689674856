import {Component, OnInit, Inject} from '@angular/core';
import {MenuService} from './menu.service';
import {Router, RouterStateSnapshot, ActivatedRoute, NavigationEnd} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {analyzeAndValidateNgModules} from '@angular/compiler';
import {CommonService} from '../../services/common.service';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import {DataService, Response} from '../../dataservice/data.service';
import {GlobalVariable} from '../../app.component';
import {filter} from 'rxjs/operators';
import {Location, LocationStrategy, PlatformLocation} from '@angular/common';

export interface Options1 {
  heading?: string;
  removeFooter?: boolean;
}

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  providers: [MenuService]
})
export class MenuComponent implements OnInit {
  //private BASE_URL = GlobalVariable;
  private BASE_API_URL = GlobalVariable;
  menuItems: any = [];
  public show: boolean = false;
  studentroomdata: any = [];
  _router: any;
  routeOptions: Options1;
  routePage: any;
  ischeckedin: any;
  storedData: any;

  constructor(
    @Inject(LOCAL_STORAGE) private storage: WebStorageService, public menuService: MenuService, public translate: TranslateService,
    private router: Router, private route: ActivatedRoute, private commonService: CommonService,
    private HttpService: DataService, private location: Location
  ) {
  }


  ngOnInit() {
    this.show = true;
    this.commonService.userDetail.subscribe(res => {
      if (res == !null) {
        this.ischeckedin = res.status_id;
      }
    });
    if (this.storage.get('user_details') !== null) {
      this.storedData = this.storage.get('user_details');
      this.ischeckedin = this.storedData.status_id;
    }
    this.menuItems = [
      {title: 'Dashboard', icon: 'menu-dashboard.svg', route: '/dashboard'},
      {title: 'Profile & Documents', icon: 'menu-profile.svg', route: '/profile'},
      {title: 'Ticket', icon: 'menu-ticket.svg', route: '/ticket'},
      {title: 'Financials', icon: 'menu-financials.svg', route: '/payment'},
      {title: 'Bookings', icon: 'menu-bookings.svg', route: '/bookinghistory'},
      {title: 'Notice Board', icon: 'menu-notice.svg', route: '/notice-board'},
      {title: 'Electricity', icon: 'menu-electricity.svg', route: '/electricity'},
      {title: 'Visitors log', icon: 'menu-visitors.svg', route: '/visitors-log'},
    ];
    if (this.ischeckedin.id !== 6) {
      this.menuItems = [
        {title: 'Dashboard', icon: 'menu-dashboard.svg', route: '/dashboard'},
        {title: 'Profile & Documents', icon: 'menu-profile.svg', route: '/profile'},
        {title: 'Financials', icon: 'menu-financials.svg', route: '/payment'},
        {title: 'Bookings', icon: 'menu-bookings.svg', route: '/bookinghistory'},
        {title: 'Notice Board', icon: 'menu-notice.svg', route: '/notice-board'},
      ];
    }
  }

  hideMenu() {
    this.commonService.sideMenuBar(false);
  }

}
