import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { RequestOptions, Headers, Http, HttpModule } from '@angular/http';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import { GlobalVariable } from '../app.component';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  BASE_API: string = GlobalVariable.BASE_API_URL;
  private API_LIST = GlobalVariable;
  response_data: any;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,
              private http: HttpClient, private httpservice: Http) { }

 

  /**
   * Common http post method with json data
   * @param customApiUrl
   * @param data
   */
  postjson(customApiUrl, data= null): Observable<Response> {
    return this.http.post(this.BASE_API + customApiUrl, data, { observe: 'response' }).pipe(map(res => this.returnValue(res) ));
  }

  /**
   *Post method with query params
   * @param {*} customApiUrl
   * @param {*} data
   * @param {*} id
   * @returns
   * @memberof DataService
   */
  post(customApiUrl, data = null, id = null) {
    //const myParams = {'outlet_id': id };
    return this.http.post(this.BASE_API + customApiUrl, data,
                          { observe: 'response' }).pipe(map(res => this.returnValue(res) ));
  }

  /**
   * Send form data to server
   *
   * @param {*} customApiUrl
   * @param {*} [data=null]
   * @returns
   * @memberof DataService
   */
  postFormData(customApiUrl, data = null) {
    const head = new Headers({
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${this.storage.get('access_token')}`,
      'processData': false,
      'mimeType': 'multipart/form-data',
    });    
    const options = new RequestOptions({ headers: head });
    return this.httpservice.post(this.BASE_API + customApiUrl, data, options);
  }

  /**
   *Common update function put method
   * @param {*} customApiUrl
   * @param {*} data
   * @param {*} id
   * @memberof DataService
   */
  put(customApiUrl, data = null, id = null) {
    if (id !== null) {
    return this.http.put(this.BASE_API + customApiUrl, data,
                        { observe: 'response' }).pipe(map(res => this.returnValue(res) ));
    } else {
      return this.http.put(this.BASE_API + customApiUrl, data,
                        { observe: 'response' }).pipe(map(res => this.returnValue(res) ));
    }
  }

  /**
   * Common create function with put method json data
   *
   * @param {*} customApiUrl
   * @param {*} data
   * @returns
   * @memberof DataService
   */
  putjson(customApiUrl, data) {
    return this.http.put(this.BASE_API + customApiUrl, data, { observe: 'response' }).pipe(map(res => this.returnValue(res) ));
  }

  /**
   * Common Http get method
   * @param customApiUrl
   * @param id
   */
  get(customApiUrl, id = null): Observable<Response> 
  {
    if (id != null) {    
      return this.http.get(this.BASE_API + customApiUrl + id,
                          { observe: 'response' }).pipe(map(res => this.returnValue(res)));
    } else {
      return this.http.get(this.BASE_API + customApiUrl, { observe: 'response' }).pipe(map(res => this.returnValue(res) ));
    }
  }

   /**
   * Common delete method
   * @param {*} customApiUrl
   * @param {*} data
   * @param {*} id
   * @memberof DataService
   */
  delete(customApiUrl, id = null) {
    const myParams = {'id': id };
    return this.http.delete(this.BASE_API + customApiUrl, { observe: 'response' })
                            .pipe(map(res => this.returnValue(res) ));
  }

    /**
   *Post method with query params
   * @param {*} customApiUrl
   * @param {*} data
   * @param {*} id
   * @returns
   * @memberof DataService
   */
  postDwonload(customApiUrl, data = null, id = null) {
    //const myParams = {'outlet_id': id };
    return this.http.post(this.BASE_API + customApiUrl, data,
                          { observe: 'response' });
  }


  /**
   * Return respospone funtion
   * @param res ;
   */
  returnValue(res: any): Response {
    let response = <Response>{};
    if (res.status === 204) {
      response = { data: null, code: res.status, status: 'No data found' };
    } else {
      response = { data: res.body.data, code: res.status, status: res.body.message };
    }
    return response;
  }
}

export interface Response {
  data: string;
  code: number;
  status: string;
}

/**
* adding header and Authorization methods
* const head = new Headers({ 'Content-type': 'application/json; charset=utf-8',
* 'Authorization': 'Bearer ${this.storage.get('token')}' });
* const options = new RequestOptions({ headers: head , params: myParams});
*/



