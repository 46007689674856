import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {GlobalVariable} from '../../app.component';
import {LOCAL_STORAGE, WebStorageService} from 'angular-webstorage-service';
import {DataService} from '../../dataservice/data.service';
import {FormBuilder} from '@angular/forms';
import {CommonService} from '../../services/common.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {DataTableDirective} from 'angular-datatables';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-refund',
  templateUrl: './refund.component.html',
  styleUrls: ['./refund.component.css']
})
export class RefundComponent implements OnInit {

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private HttpService: DataService,
              private commonservice: CommonService, private modalService: NgbModal, private toastrService: ToastrService,
              private elementRef: ElementRef, private router: Router) { }
  private BASE_URL = GlobalVariable;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  refundAmount:number;
  refunds: any = [];
  walletBalance:number;
  loading: boolean = false;
  refundNotes: any;
  enableRefundOTP: boolean = false;
  refundOTP:any;

  ngOnInit() {
    this.dtOptions = {
      columnDefs: [
        { targets: 2, type: 'date' }
      ]
    };
    this.getRefunds();
    this.commonservice.WalletBalance.subscribe(res => {
      if (res >= 0) {
        this.walletBalance = res;
      }
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }


  getRefunds(){
    this.HttpService.get(this.BASE_URL.REFUNDS_URL).subscribe(res => {
      if(res && res['data']){
        this.refunds = res['data'];
        this.rerender();
      }
    });
  }

  handleRefundOTPRequest() {
    this.loading = true;
    const requestJSON = {
      'refund_amount': this.refundAmount,
    };
    this.HttpService.post(this.BASE_URL.REFUND_OTP_URL, requestJSON).subscribe(res => {
      this.loading = false;
      if (res.code >= 200 && res.code <= 299) {
        this.enableRefundOTP = true;
      } else {
        this.toastrService.error(res['status']);
      }
    }, error => {
      this.loading = false;
      this.toastrService.error(error.error.message);
    });
  }

  handleRequestRefund() {
    this.loading = true;
    const requestJSON = {
      'refund_amount': this.refundAmount,
      'otp': this.refundOTP
    };
    this.HttpService.post(this.BASE_URL.REFUNDS_URL, requestJSON).subscribe(res => {
      this.loading = false;
      if(res.code >= 200 && res.code <= 299){
        this.toastrService.success('Refund requested successfully');
        this.modalService.dismissAll();
        this.refunds.push(res.data);
        this.commonservice.setWalletBalance(this.walletBalance - this.refundAmount);
        this.refundAmount = 0;
        this.enableRefundOTP = false;
      }
    }, error => {
      this.loading = false;
      this.toastrService.error(error.error.message);
    });
  }

  openModal(modalRef) {
    this.modalService.open(modalRef, { size: 'lg',
      windowClass: 'modalfeedback', centered: true
    });
  }

  showRefundDetailsModal(modalId, refundNotes) {
    this.openModal(modalId);
    this.refundNotes = refundNotes;
  }

  closeModal() {
    this.modalService.dismissAll();
  }

}
