import { Component, OnInit, ViewChild, TemplateRef, ElementRef, HostListener, Inject, } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DataService, Response } from '../../dataservice/data.service';
import { LOCAL_STORAGE, WebStorageService } from 'angular-webstorage-service';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { GlobalVariable } from '../../app.component';
import { CommonService } from '../../services/common.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-uploaddocument',
  templateUrl: './uploaddocument.component.html',
  styleUrls: ['./uploaddocument.component.css']
})
export class UploaddocumentComponent implements OnInit {
  private BASE_URL = GlobalVariable;
  doucmentUploadForm: FormGroup;
  formdata: any;
  submitted: boolean = true;
  loading: Boolean = false;
  id: String;
  documentNameList: any = [];
  documentTypeList: any = [];
  value: number = 3;
  url: any;
  imageurl: any;
  updateDocId: any;
  uploadedFilename: any;
  exdocumentPath: any;
  filesToUpload: Array<File> = [];
  fileToUpload: File = null;
  uploadFor: any = [];
  fileUpdated: boolean = false;
  studentsDocument: any = [];
  guardianDocument: any = [];
  expiryDate: any;
  documentName: any;
  documentType: any;
  idNumber: any;
  documentFor: any;
  document: boolean = false;

  public max = new Date(2009, 3, 21, 20, 30);
  @ViewChild('myModal', { read: ElementRef }) myModal: ElementRef;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private HttpService: DataService, public formBuilder: FormBuilder,
    private commonservice: CommonService, private modalService: NgbModal, private toastrService: ToastrService,
    private elementRef: ElementRef, private router: Router, public ngxSmartModalService: NgxSmartModalService) { }

  ngOnInit() {
    this.studentsDocument = {
      'status_id': { 'name': null }
    };
    this.updateDocId = null;
    this.uploadFor = [{ 'name': 'Student' }, { 'name': 'Guardian' }];
    // this.doucmentUploadForm =this.formBuilder.group({
    //   'document_name' : ['', Validators.compose([Validators.required])],
    //   'document_type' :['National Id', Validators.compose([Validators.required])],
    //   'id_number' :['', Validators.compose([Validators.required])],
    //   'expiry_date' : ['', Validators.compose([Validators.required])],
    //   'document_for' : ['Student', Validators.compose([Validators.required])],
    //   'document' : ['', Validators.compose([Validators.required])],
    // });
    const documentList = this.HttpService.get(this.BASE_URL.DOCUMENT_NAME_LIST);
    const documenttypeList = this.HttpService.get(this.BASE_URL.DOCUMENT_TYPE_LIST);
    forkJoin([documentList, documenttypeList]).subscribe(result => {
      this.documentNameList = result[0].data;
      this.documentTypeList = result[1].data;
    }, error => {

    });
    this.getUserDocuments();

  }

  getUpdatedata() {
    return this.doucmentUploadForm.controls;
  }

  getUserDocuments() {
    this.loading = true;
    this.HttpService.get(this.BASE_URL.ON_BOARDING_DOCUMENT).subscribe(res => {
      if (res.code === 200) {
        if (res.data['Student'] !== undefined) {
          this.studentsDocument = res.data['Student'];
          this.loading = false;
        }
        if (res.data['Guardian'] !== undefined) {
          this.guardianDocument = res.data['Guardian'];
          this.loading = false;
        }
      }
    });
  }

  editDocument(from, docfor, popid, docid) {
    this.exdocumentPath = from.file_path;
    this.documentType = from.doc_type;
    this.updateDocId = from.id;
    this.fileToUpload = null;
    this.documentFor = docfor;
    this.uploadedFilename = null;
    this.documentName = null;
    this.fileUpdated = false;
    this.modalService.dismissAll(popid);
    this.modalService.open(popid, {
      size: 'lg',
      windowClass: 'modalfeedback', centered: true
    });
    this.HttpService.get(this.BASE_URL.DOC_BY_ID_LIST + docid).subscribe(res => {
      if (res.code === 200) {
        this.loading = false;
        this.documentNameList = res.data;
      }
    });
    this.expiryDate = from.expiry_date;
    this.documentName = from.doc_name_id;
    if (this.documentName === undefined) {
      this.documentName = null;
    }
    this.documentType = from.doc_type;
    this.idNumber = from.id_number;
    if (from.id !== null && from.id !== undefined) {
      this.document = this.exdocumentPath;
    }
  }

  closeModal(popid) {
    this.fileToUpload = null;
    this.document = false;
    this.modalService.dismissAll(popid);
  }

  // uploadNewDoc () {
  //   this.updateDocId = null;
  //   this.doucmentUploadForm.patchValue({'document_name' :1, 'document_type': 'National ID',
  //    'document_for': 'Student','id_number':'','expiry_date': ''});
  // }

  uploadDocument() {
    this.loading = true;
    //console.log(this.expiryDate); return;
    // const dat1 = new Date(this.expiryDate);
    // const expdate = dat1.toISOString().substring(0,10);
    this.formdata = new FormData();
    var dat1 = new String(this.expiryDate);
    var splitted = dat1.split("00:", 2);
    //  form.value.birth_date =splitted[0];
    this.formdata.append('document', this.fileToUpload);
    this.formdata.append('document_name', this.documentName);
    this.formdata.append('document_for', this.documentFor);
    this.formdata.append('id_number', this.idNumber);
    this.formdata.append('document_type', this.documentType);
    this.formdata.append('expiry_date', splitted[0]);
    console.log(this.fileToUpload)
    if (this.updateDocId === null || this.updateDocId === undefined) {
      this.HttpService.postFormData(this.BASE_URL.ON_BOARDING_DOCUMENT, this.formdata).subscribe(res => {
        this.updateDocId = null;
        this.toastrService.success(res.json().message);
        this.getUserDocuments();
        this.loading = false;
        this.document = false;
      }, error => {
        this.toastrService.error(error.json().message);
        this.loading = false;
        this.document = false;
      });
    } else {
      this.formdata.append('is_uploaded', this.fileUpdated);
      this.HttpService.postFormData(this.BASE_URL.ON_BOARDING_DOCUMENT_UPDATE_URL + this.updateDocId, this.formdata).subscribe(res => {
        this.updateDocId = null;
        this.toastrService.success(res.json().message);
        this.getUserDocuments();
        this.loading = false;
        this.document = false;
      }, error => {
        this.toastrService.error(error.json().message);
        this.loading = false;
        this.document = false;
      });
    }
  }

  fileUploaded(event) {
    this.loading = true;
    this.uploadedFilename = null;
    this.fileUpdated = false;
    if (event.target.files && event.target.files[0]) {
      this.document = true;
      const reader = new FileReader();
      this.fileToUpload = event.target.files[0];
      this.uploadedFilename = this.fileToUpload.name;
      const mimeType = this.fileToUpload.type;
      this.fileUpdated = true;
      if (mimeType.match(/image\/*/) == null) {
        if (mimeType !== 'application/pdf') {
          this.toastrService.error('Selected document is of the wrong type. Upload a supported file type');
          this.fileToUpload = null;
          this.fileUpdated = false;
          return;
        }
      }
      if (this.fileToUpload.size > 10000000) {
        this.toastrService.error('Size of the selected file must be less than 10MB');// = ;
        this.fileToUpload = null;
        this.fileUpdated = false;
        return;
      }

      let file: File;
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        this.url = reader.result;
        this.loading = false;
      };
    }
  }
}
